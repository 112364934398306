import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import sanityClient from "../utils/client"
import Section from "../components/section/Section"
import Seo from "../components/seo/seo"
import "../styles/index.scss"

const IndexPage = ({ data }) => {
  const [clientProduct, setClientProduct] = useState(null)

  // const id = data.allSanityHome.edges[0].node._id
  useEffect(() => {
    sanityClient
      .fetch(
        `*[ _id=="${data.allSanityHome.edges[0].node._id}" ][0]{_id,sections}`
      )
      .then(data => setClientProduct(data))
      .catch(console.error)
  }, [data])

  const sections = data.allSanityHome.edges[0].node.sections.sections
  return (
    <div className="relative -mt-29 ">
      <Seo title="Home" />

      <Section
        sections={sections}
        clientSections={clientProduct?.sections?.sections}
      />
    </div>
  )
}
export const query = graphql`
  {
    allSanityHome {
      edges {
        node {
          _id
          sections {
            sections {
              ... on SanityCopy {
                _key
                _type
                copy {
                  _key
                  children {
                    marks
                    text
                    _key
                    _type
                  }
                  list
                  style
                  _type
                }
                link {
                  title
                  _type
                  _key
                  linkObject {
                    ... on SanityCollection {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityAboutLink {
                      id
                      _type
                      slug {
                        current
                      }
                      title
                    }
                    ... on SanityProduct {
                      id
                      _type
                      store {
                        slug {
                          current
                        }
                      }
                    }
                    ... on SanityPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              ... on SanityCampaignFull {
                _key
                _type
                show {
                  desktop
                  mobile
                }
                title
                image {
                  asset {
                    url
                    gatsbyImageData(
                      backgroundColor: "transparent"
                      layout: FULL_WIDTH
                      placeholder: NONE
                    )
                  }
                }
                link {
                  ... on SanityAboutLink {
                    id
                    _type
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityCollection {
                    id
                    _type
                    slug {
                      current
                    }
                  }
                  ... on SanityProduct {
                    id
                    _type
                    store {
                      slug {
                        current
                      }
                    }
                  }
                  ... on SanityPage {
                    id
                    _type
                    slug {
                      current
                    }
                  }
                }
              }
              ... on SanityCampaignGrid {
                _key
                _type
                image {
                  _key
                  asset {
                    gatsbyImageData(layout: FULL_WIDTH)
                    title
                    url
                    altText
                  }
                  _type
                }
                products {
                  _key
                  products {
                    id
                    _id
                    featuredTag {
                      store {
                        title
                        slug {
                          current
                        }
                      }
                    }
                    cardTextColour {
                      hex
                    }
                    cardImages {
                      hoverImage {
                        asset {
                          url
                          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                        }
                      }
                      image {
                        asset {
                          url
                          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                        }
                      }
                    }
                    store {
                      id
                      slug {
                        current
                      }
                      status
                      tags
                      title
                      priceRange {
                        minVariantPrice
                        maxVariantPrice
                      }
                      variants {
                        store {
                          productId
                          price
                          id
                          status
                          title
                          previewImageUrl
                        }
                        _id
                      }
                      productType
                      previewImageUrl
                      isDeleted
                    }
                    images {
                      asset {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                        url
                      }
                    }
                  }
                }
                video {
                  asset {
                    filename
                    playbackId
                    status
                    thumbTime
                    assetId
                  }
                }
              }
              ... on SanityImageButtons {
                _key
                _type
                buttons {
                  _key
                  title
                  link {
                    linkObject {
                      ... on SanityAboutLink {
                        id
                        _type
                        slug {
                          current
                        }
                        title
                      }
                      ... on SanityCollection {
                        id
                        _type
                        slug {
                          current
                        }
                      }
                      ... on SanityProduct {
                        id
                        _type
                        store {
                          slug {
                            current
                          }
                        }
                      }
                      ... on SanityPage {
                        id
                        _type
                        slug {
                          current
                        }
                      }
                    }
                  }
                  image {
                    asset {
                      gatsbyImageData(layout: FULL_WIDTH)
                      url
                    }
                  }
                }
              }
              ... on SanityProductSection {
                _key
                _type
                sectionLink {
                  title
                  linkObject {
                    ... on SanityCollection {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityProduct {
                      id
                      _type
                      store {
                        slug {
                          current
                        }
                      }
                    }
                    ... on SanityPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                  }
                }

                products {
                  products {
                    id
                    _id
                    featuredTag {
                      store {
                        title
                        slug {
                          current
                        }
                      }
                    }
                    cardTextColour {
                      hex
                    }
                    cardImages {
                      hoverImage {
                        asset {
                          url
                          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                        }
                      }
                      image {
                        asset {
                          url
                          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                        }
                      }
                    }
                    store {
                      id
                      slug {
                        current
                      }
                      status
                      tags
                      title
                      priceRange {
                        minVariantPrice
                        maxVariantPrice
                      }
                      variants {
                        store {
                          productId
                          price
                          id
                          status
                          title
                          previewImageUrl
                        }
                        _id
                      }
                      productType
                      previewImageUrl
                      isDeleted
                    }
                    images {
                      asset {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                        url
                      }
                    }
                  }
                }
              }
              ... on SanityTitle {
                _key
                _type
                sanityTitle: title {
                  title
                  linkObject {
                    ... on SanityAboutLink {
                      id
                      _type
                      slug {
                        current
                      }
                      title
                    }
                    ... on SanityCollection {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityProduct {
                      id
                      _type
                      store {
                        slug {
                          current
                        }
                      }
                    }
                    ... on SanityPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              ... on SanityCarousel {
                _key
                _type
                show {
                  desktop
                  mobile
                }
                images {
                  asset {
                    url
                    gatsbyImageData(
                      formats: WEBP
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
